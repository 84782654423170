.stand-out-link {
    padding: 8rem 10% 18rem;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 10rem));
    display: flex;
    justify-content: center;
    position: relative;
    .stand-out-link__background-image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        picture {
            height: 100%;
            width: 100%;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center bottom;
        }
    }
    .stand-out-link__box { 
        max-width: 60rem;
        box-sizing: border-box;
        background-color: map-get($map: $colors, $key: _01-02);
        padding: 4rem;
        position: relative;
        z-index: 10;
        h2 {
            margin-bottom: 2rem;
        }
        p {
            margin-bottom: 1rem;
        }
        .stand-out-link__button-row {
            position: absolute;
            bottom: -2rem;
            left: 0;
            right: 2rem;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            &::before {
                content: '';
                width: calc(100% - 10rem);
                height: 0.2rem;
                background-color:  map-get($colors, _be);
                margin-bottom: 1rem;
            }
        }
    }
}