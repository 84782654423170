/* ------------------------------------------------------------------------------------------------------------------------ */
/* GRID SYSTEM ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

.grid {
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;

    &.grid_reverse {
        flex-direction:row-reverse;
    }

    &.grid_valign-middle {
        align-items:center;
    }
    &.grid_valign-bottom {
        align-items:flex-end;
    }

    @media screen and (max-width:768px) {
        margin-left:0 !important; margin-right:0 !important;

    }

    /* Widths */
    .width_1-12 { width:(1 / 12) * 100%; }
    .width_2-12 { width:(2 / 12) * 100%; }
    .width_3-12 { width:(3 / 12) * 100%; }
    .width_4-12 { width:(4 / 12) * 100%; }
    .width_5-12 { width:(5 / 12) * 100%; }
    .width_6-12 { width:(6 / 12) * 100%; }
    .width_7-12 { width:(7 / 12) * 100%; }
    .width_8-12 { width:(8 / 12) * 100%; }
    .width_9-12 { width:(9 / 12) * 100%; }
    .width_10-12 { width:(10 / 12) * 100%; }
    .width_11-12 { width:(11 / 12) * 100%; }
    .width_12-12 { width:(12 / 12) * 100%; }

 


    /* Column */
    .column {
        @include flex-column;
        box-sizing:border-box;

        @media screen and (max-width:768px) {
            padding-left:0 !important; padding-right:0 !important;
            width: 48%;
            
            
        }

        @media screen and (max-width: 520px) {
            width: 100%;
        }
    }

    /* Gutter 12px */
    &.gutter_12 {
        margin-left:-6px; margin-right:-6px;

        & > .column { padding-left:6px; padding-right:6px; }
    }

    /* Gutter 24px */
    &.gutter_24 {
        margin-left:-12px; margin-right:-12px;

        & > .column { padding-left:12px; padding-right:12px; }
    }

    /* Gutter 48px */
    &.gutter_48 {
        margin-left:-24px; margin-right:-24px;

        & > .column { padding-left:24px; padding-right:24px; }
    }

    /* Gutter 96px */
    &.gutter_96 {
        margin-left:-48px; margin-right:-48px;

        & > .column { padding-left:48px; padding-right:48px; }
    }
}
