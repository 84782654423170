
.navigation {
    display: flex;
    justify-content: space-between;

    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    position: fixed;
    padding: 1.5rem 10%;
    z-index: 1000;
    box-sizing: border-box;
    background-color: rgba($color: white, $alpha: 0);
    transition: 0.5s;
    transition-property: padding, background-color;
    &__burger {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid white;
        border-radius: 200px;
        padding: 13px;
        height: 30px;
        width: 30px;
        cursor: pointer;
        

        

        span {
            
            width: 20px;
            margin: 2px;
            display: block;
            height: 2px;
            background-color: white;
            transform-origin: 6px 1px;
            @include animate;

        }        

        &.open {

        
            span:first-child {
              transform: rotate(45deg);
            }
            span:nth-child(2) {
              display: none;
            }
            span:last-child {
              transform: rotate(-45deg);
            }
        }
    }
}

.navigation__burger:hover{
    @include animate;
    border: 1px solid map-get($map: $colors, $key:_tb);;

    span {
        background-color: map-get($map: $colors, $key:_tb);
    }
    
}

.navigation__logo {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    img {
        height: 50px;
    }
}
.navigation__logo-mobile {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    align-items: center;
    img {
        height: 50px;
    }
}
.is-sticky-header {
    .navigation__logo-mobile {
        display: flex;
    }  
}

.navigation__show {
    animation-name: menu-open;
    animation-duration: 0.5s;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    position: fixed;
    background-color: #223050;
    z-index: 900;
    animation: fade_in_show 0.5s;
    display: flex;
    align-items: flex-end;
}

@keyframes fade_in_show {
    0% {
        opacity: 0;
        height: 0;
    }

    100% {
        opacity: 1;
        height: 100%;
    }
}

@keyframes fade_in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.is-sticky-header .navigation{
    background-color: rgba($color: white, $alpha: 0.85);
    animation: fade_in 0.5s;
    padding: 0.5rem 10%;

    &__burger {
        border-color: map-get($map: $colors, $key:_tb);
    }
    span {
        background-color: map-get($map: $colors, $key:_tb );
        
    }
}
.stop-scrolling .navigation{
    background-color: #223050;
    border-bottom: 2px solid map-get($colors, _tb);
} 

.navigation__logo {
    animation: fade_in_show 0.5s;
}


.navigation__show__main {
    display: flex;
    align-items: center;
    width: 100%;
    height: calc(100% - 160px);
    margin: 0 10%;
    padding: 20px 0;
    box-sizing: border-box;
    .navigation__show__wrapper {
        margin: auto;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @media screen and (max-width:768px) {
            flex-wrap: nowrap;
            flex-direction: column;
            align-items: center;
        }
    }
}

.navigation__show__main__socials {
    width: 100%;
    ul {
        display: flex;
        list-style: none;
        li {
            display: inline-block;
            height: 3rem;
            width: 3rem;
            border-radius: 50%;
            border: 2px solid map-get($colors, _white);
            margin-right: 0.5rem;
            &:hover {
                background-color: #192749;
            }
            a {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                img { 
                    height: 15px;
                    box-sizing: contain;
                }
            }
        }
    }

}

.navigation__show__main__menu {
    width: 60%;
}

.navigation__menu__list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    height: 300px;
    @media screen and (max-width:768px) {
        flex-wrap: nowrap;
        text-align: center;
        height: auto;
    }
    a {
        color: map-get($colors, _white);
        font-size: 16px;
    }
}

.navigation__show__main__menu li {
    list-style: none;
    color: white;
    margin-bottom: .625rem;
    margin-right: 2rem;
    a {
        font-size: 18px;
    }
    @media screen and (max-width:768px) {
        margin-right: 0;
    }
}

.navigation__show__main__address {
    text-align: right;
    @media screen and (max-width:768px) {
        align-self: flex-end;
        margin-top: 2rem;
    }
}
.navigation__show__main__address__content {
    color: white;
    text-transform: uppercase;
}

.navigation__socials__list {
    list-style: none;
    @media screen and (max-width:768px) { 
        margin-bottom: 20px;
    }
}
