/* ------------------------------------------------------------------------------------------------------------------------ */
/* FOOTER ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.footer {
    background-color: map-get($colors, _02-01);
    padding: 4rem 4rem 2rem;
    font-size: 14px;
    color: #fff;
    &.dark-footer {
        background-color: map-get($colors, _01-03);
    }
    &.top-border {
        border-top: 4px solid map-get($colors, _tb);
    }
    .footer__top-row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .footer__menu {
            max-width: 30rem;
            margin-bottom: 2rem;
            .footer__menu__list {
                list-style-type: none;
                display: flex;
                flex-wrap: wrap;
                li {
                    box-sizing: border-box;
                    width: 50%;
                    padding: 0 1rem 1rem 0;
                    a {
                        color: map-get($colors, _white);
                        font-size: 14px;
                    }
                }
            }
        }
        .footer__address {
            text-align: right;
            justify-self: flex-end;
            margin-bottom: 2rem;
            .footer__address__logo {
                margin: 0 0 1rem;
                img {
                    width: 130px;
                    height: 73.31px;
                }
            }
            .footer__address__content {
                color: map-get($colors, _white);
                text-transform: uppercase;
            }
        }
        @media screen and (max-width:768px) {
            flex-wrap: wrap-reverse;
            .footer__address {
                width: 100%;
            }
            .footer__menu {
                max-width: 100%;
            }
        }
    }

    .footer__bottom-row {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        .footer__copyright {
            width: 100%;
            a {
                color: #fff;
            }
        }
        .footer__socials-legal {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .footer__socials__list {
                display: flex;
                list-style: none;
                margin-bottom: 1rem;
                li {
                    display: inline-block;
                    height: 2rem;
                    width: 2rem;
                    border-radius: 50%;
                    border: 2px solid map-get($colors, _white);
                    margin-left: 0.5rem;
                    &:hover {
                        background-color: #192749;
                    }
                    a {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img { 
                            height: 15px;
                            box-sizing: contain;
                        }
                    }
                }
            }
        }
        @media screen and (max-width:960px) {
            flex-wrap: wrap-reverse;
            .footer__socials-legal {
                align-items: flex-start;
                margin-bottom: 0.5rem;
            }
        }
    }

    @media screen and (max-width:768px) {
        padding: 4rem 2rem 2rem;
    }
}
