.pagination {
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
        &:hover, &:focus, .is-current {
            text-decoration: underline;
        }
    }

    ul {
        padding: 0;
        margin: 0 0.25rem;
        display: block;
        width: auto;
        li {
            width: auto;
            height: auto;
            padding: 0;
            display: inline-block;
            margin: 0 0.25rem;
            a {
                font-weight: 700;
            }
        }
    }

}
