/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.btns-wrapper {

    &:not(:first-child) {
        padding-top:32px;

        @media screen and (max-width:768px) {
            padding-top:18px;
        }
    }

    &.direction-column {
        @include flex-column;

        .btn:not(:last-child) {
            margin-bottom:6px;
        }
    }

    &.direction-row {
        @include flex-row;

        @media screen and (max-width:768px) {
            flex-direction:column;
            align-items:flex-start;
        }

        &.align-center {

            @media screen and (min-width:769px) {
                justify-content:center;
            }
            @media screen and (max-width:768px) {
                align-items:center;
            }
        }
        &.align-right {

            @media screen and (min-width:769px) {
                justify-content:flex-end;
            }
            @media screen and (max-width:768px) {
                align-items:flex-end;
            }
        }

        .btn:not(:last-child) {

            @media screen and (min-width:769px) {
                margin-right:6px;
            }
            @media screen and (max-width:768px) {
                margin-bottom:6px;
            }
        }
    }
}

    .btn {
        @include animate;
        display:inline-block;
        font-weight:700;
        box-sizing:border-box;

        &:disabled {
            cursor:default;
        }
    }


/*
 * Btn (01)
 */

.btn-01 {
    padding:16px 28px;

    &.btn_style_01-01 {
        color:#FFF;
        background:map-get($colors, _01-01) !important;

        &.btn_has-icon svg {
            fill:#FFF;
        }

        @media screen and (min-width:769px) {
            &:not(:disabled):hover,
            &:not(:disabled):focus {
            }
        }
    }

    &.btn_style_white {
        color:map-get($colors, _01-01);
        background:#FFF !important;

        &.btn_has-icon svg {
            fill:map-get($colors, _01-01);
        }

        @media screen and (min-width:769px) {
            &:not(:disabled):hover,
            &:not(:disabled):focus {
            }
        }
    }
}


/*
 * Btn (Has Icon)
 */

.btn_has-icon {
    @include animate;
    display:flex;
    flex-direction:row;
    flex-wrap:nowrap;
    align-items:center;

    svg {
        @include animate;
        display:block;
        flex-shrink:0;
        width:16px; height:16px;
    }

    .btn-icon {
        flex-shrink:0;
    }
    .btn-label {

        @media screen and (max-width:768px) {
            flex:1 1 auto;
        }
    }

    // Icon (Left)
    &:not(.icon-right) .btn-icon {
        margin-right:12px;

        @media screen and (max-width:768px) {
            margin-right:9px;
        }
    }

    // Icon (Right)
    &.icon-right .btn-icon {
        order:2;
        margin-left:12px;

        @media screen and (max-width:768px) {
            margin-left:9px;
        }
    }

    // Icon (Round)
    &.icon-round .btn-icon {
        @include flex-column;
        justify-content:center;
        align-items:center;
        width:48px; height:48px;
        border-radius:100%;
    }

    // Icon (Right-End)
    &.icon-right-end {
        justify-content:space-between;
    }

    // Icon (Rotate -90deg)
    &.icon-minus-90-deg svg {
        transform:rotate(-90deg);
    }

    // Icon (Rotate 180deg)
    &.icon-180-deg svg {
        transform:rotate(180deg);
    }
}


/*
 * Btn (Round)
 */

.btn-round {
    display:flex;
    flex-direction:column;
    flex-wrap:nowrap;
    justify-content:center;
    align-items:center;
    width:48px; height:48px;
    border-radius:100%;
    overflow:hidden;

    svg {
        display:block;
        flex-shrink:0;
        width:18px; height:18px;
    }
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (PREV / NEXT) --------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.btn-prev,
.btn-next {
    @include animate;
    @include flex-column;
    @include absolute(2);
    justify-content:center;
    align-items:center;
    top:50%;
    width:48px; height:48px;
    transform:translateY(-50%);

    svg {
        @include animate;
        width:24px; height:24px;
        fill:map-get($colors, _01-01);

        @media screen and (max-width:768px) {
            width:16px !important; height:16px !important;
        }
    }

    &.swiper-button-disabled {
        cursor:default;
        opacity:0;
        visibility:hidden;
    }

    @media screen and (min-width:769px) {
        &:not(.swiper-button-disabled):hover svg,
        &:not(.swiper-button-disabled):focus svg {
            fill:map-get($colors, _01-01);
        }
    }
}
.btn-prev {
    left:48px;

    @media screen and (max-width:768px) {
        left:0;
    }

    svg {
        transform:rotate(90deg);
    }
}
.btn-next {
    right:48px;

    @media screen and (max-width:768px) {
        right:0;
    }

    svg {
        transform:rotate(-90deg);
    }
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (MODAL CLOSE) --------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.modal_btn-close {
    position:fixed;
    top:48px; right:48px;
    z-index:9;

    svg {
        @include animate;
    }

    @media screen and (min-width:769px) {
        &:hover svg,
        &:focus svg {
            transform:rotate(180deg);
        }
    }
}


.btn {
    padding: 15px 30px;
    border-radius: 40px;
    color: white;
    font-size: 1rem;
}


.btn--main {
    text-transform: uppercase;

}

.btn--tb {
    background-color: map-get($colors, _tb);
    &:hover {
        background-color: darken($color: map-get($colors, _tb), $amount: 10%);
    }
}
.btn--ch {
    background-color: map-get($colors, _ch);
    &:hover {
        background-color: darken($color: map-get($colors, _ch), $amount: 10%);
    }
}
.btn--c {
    background-color: map-get($colors, _c);
    &:hover {
        background-color: darken($color: map-get($colors, _c), $amount: 10%);
    }
}
.btn--em {
    background-color: map-get($colors, _em);
    &:hover {
        background-color: darken($color: map-get($colors, _em), $amount: 10%);
    }
}
.btn--be {
    background-color: map-get($colors, _be);
    &:hover {
        background-color: darken($color: map-get($colors, _be), $amount: 5%);
    }
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (SLIDER) -------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.slider_btn-next {
    background-color: map-get($colors, _white);
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &.swiper-button-next {
        &::after {
            content: '';
        }
    }
    &.swiper-button-prev {
        &::after {
            content: '';
        }
    }
    &::before {
        content: '';
        position: absolute;
        left: 0.5rem;
        top: 0.5rem;
        right: 0.5rem;
        bottom: 0.5rem;
        border: 0.1rem dashed map-get($colors, _01-01);
        border-radius: 50%;
    }
    svg {
        path {
            fill: map-get($colors, _tb);
        }    
    }
}
