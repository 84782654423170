.intro {
    padding: 8rem 10%;
    h1 {
        margin-bottom: 0.5rem;
        &.--em {color: map-get($colors, _em);}
        &.--c {color: map-get($colors, _c);}
        &.--ch {color: map-get($colors, _ch);}
        &.--be {color: map-get($colors, _be);}
        &.--tb {color: map-get($colors, _tb);}
    }
    .underline {
        display: block;
        width: 100%;
        max-width: 30rem;
        height: 0.2rem;
        background-color: #e0e0e0;
        margin-bottom: 2rem;
    } 
    .subtitle {
        color: rgb(39, 39, 39);
        margin-bottom: 2rem;
    }
    p {
        margin-bottom: 1rem;
    }
}