/* ------------------------------------------------------------------------------------------------------------------------ */
/* FIELD ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

.field {
	padding-top:24px;

	@media screen and (max-width:768px)
	{
		padding-top:18px;
	}
}
	.field-label {
		@include flex-row;
		padding-bottom:6px;
        line-height:map-get($line_heights, _base);
		color:map-get($colors, _01-01);
        font-size:16px;

		em {
			margin-left:auto; padding-left:24px;
			color:map-get($colors, _01-02);
			font-weight:400;
			text-transform:none;
		}
	}


/*
 * Form Light Modifier
 */

.form-light {

    .field-label {
        color:#FFF;

        em {
            color:rgba(#FFF, .64);
        }
    }
}


.confirm-submit {

    @media screen and (min-width:769px) {
        @include flex-row;
        justify-content: space-between;
    }

}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* FIELD FEEDBACK --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.field-feedback {
	@include animate;
	@include relative;
	max-height:0;
	overflow:hidden;
	opacity:0;

	span {
		display:block;
		padding-top:12px;
		line-height:map-get($line_heights, _small);
		font-size:map-get($font_sizes, _small);
		color:map-get($colors, _error);
	}
}


/*
 * Form Light Modifier
 */

.form-light {

    .field-feedback span {
        color:rgba(#FFF, .64);
    }
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* FIELD BADGES ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.badge-valid,
.badge-invalid {
	@include animate;
	@include flex-column;
	justify-content:center;
	align-items:center;
	position:absolute;
	right:-12px; bottom:-12px;
	z-index:2;
	width:24px; height:24px;
	border-radius:100%;
	opacity:0;

	svg {
		display:block;
		width:12px; height:12px;
		fill:#FFF;
	}
}
.badge-valid {
	background:map-get($colors, _valid);
}
.badge-invalid {
	background:map-get($colors, _error);
}

.input-radio,
.input-checkbox {

	.badge-valid,
	.badge-invalid {
		top:0; left:26px; right:auto;
		margin-top:-2px;
		width:8px; height:8px;

		svg {
			display:none;
		}
	}
}

/* --- Valid --- */
.is-valid {

	.field-feedback,
	.badge-invalid {
		opacity:0;
	}
	.badge-valid {
		opacity:1;
	}
}

/* --- Invalid --- */
.is-invalid {

	.badge-valid {
		opacity:0;
	}
	.field-feedback,
	.badge-invalid {
		opacity:1;
	}

	.field-feedback {
		max-height:none;
	}
}


/*
 * Form Light Modifier
 */

.form-light {

    .badge-valid,
    .badge-invalid {
    	background:#FFF;
    }
    .badge-valid svg {
        fill:map-get($colors, _valid);
    }
    .badge-invalid svg {
    	fill:map-get($colors, _error);
    }
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* INPUT TEXT / TEXTAREA -------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.input-text {
	@include animate;
	@include relative;
	border-bottom:2px solid rgba(map-get($colors, _01-01), .12);

	input,
	textarea {
		@include animate;
		margin:0; padding:16px 0;
		width:100%; height:auto;
		line-height:map-get($line_heights, _base);
		color:map-get($colors, _01-01);
		font-family:map-get($font_families, _01);
		font-size:100%; font-size:16px;
		box-sizing:border-box;
		appearance:none;

		@media screen and (max-width:768px)
		{
			padding:12px 0;
		}
	}
	input {

		&:not([value=""]) + .label_in-field,
		&:focus + .label_in-field {
			z-index:1;
			padding:0;
			font-size:8px;
			opacity:.48;
		}

		@media screen and (min-width:769px)
		{
			&:not(:disabled):hover,
			&:not(:disabled):focus {
				position:relative;
				z-index:2;
			}
		}

		&:disabled {
			color:rgba(map-get($colors, _01-01), .24);
			cursor:default;
		}
	}

	.label_in-field {
		@include animate;
		@include full-size;
		padding:16px 0;
		line-height:map-get($line_heights, _small);
		color:map-get($colors, _01-01);
		font-size:16px;
		box-sizing:border-box;
		cursor:pointer;

        @media screen and (max-width:768px)
		{
			padding:12px 0;
		}
	}
}


/*
 * Form Light Modifier
 */

.form-light {

    .input-text {
    	border-color:#FFF;

        input,
    	textarea,
        .label_in-field {
    		color:#FFF;
    	}
        input:disabled {
			color:rgba(#FFF, .24);
    	}
    }
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* SELECT ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.field-select {
	@include relative;

	select {
		@include animate;
		@include relative;
		margin:0; padding:16px 34px 16px 0;
		width:100%; height:auto;
		line-height:map-get($line_heights, _base);
		color:map-get($colors, _01-01);
		font-family:map-get($font_families, _01);
		font-size:100%; font-size:16px;
		box-sizing:border-box;
		background:none;
		border-bottom:1px solid rgba(map-get($colors, _01-01), .12);
		appearance:none;

		@media screen and (max-width:768px)
		{
			padding:12px 34px 12px 0;
		}

		&:disabled {
			color:map-get($colors, _01-01);
			cursor:default;

			& + .chevron {
				opacity:.24;
			}
		}
	}

	.chevron {
		@include animate;
		position:absolute;
		top:50%; right:24px;
		z-index:2;
		margin-top:-6px;
		width:12px; height:12px;
		transform:rotate(90deg);

		svg {
			display:block;
			width:12px; height:12px;
			fill:map-get($colors, _01-01);
		}
	}
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* INPUT RADIO / CHECKBOX ------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.input-radio,
.input-checkbox {
	@include animate;
	@include relative;
	@include flex-row;
	align-items:center;

	input {
		position:absolute;
		top:50%; left:0;
		z-index:1;
		margin:0; padding:0;
		width:32px; height:32px;
		line-height:1;
		color:map-get($colors, _01-01);
		font-size:100%; font-size:16px;
		box-sizing:border-box;
		background:none;
		border:0 solid;
		border-radius:0;
		opacity:0;
		cursor:pointer;
		appearance:none;
		transform:translateY(-50%);

		&:checked:not(:disabled) + span,
		&:checked:not(:disabled) + span {

			&:after,
			svg {
				opacity:1;
			}
		}

		&:disabled {
			cursor:default;

			& + span + label {
				opacity:.24;
				cursor:default;
			}
		}
	}

	input + span {
		@include animate;
		@include flex-column;
		justify-content:center;
		align-items:center;
		flex-shrink:0;
		width:32px; height:32px;
		border:2px solid rgba(map-get($colors, _01-01), .12);
		box-sizing:border-box;

		&:after,
		svg {
			@include animate;
			display:block;
			opacity:0;
		}
	}

	input + span + label {
		flex:1 1 auto;
		flex-direction:column;
		margin:0; padding:0 0 0 18px;
		line-height:1.2;
        font-size:16px;
		text-align:left;
		cursor:pointer;
	}

	& + .field-feedback:before {
		left:6px;
	}
}

.input-radio {

	input + span {
		border-radius:100%;

		&:after {
			content:"";
			width:8px; height:8px;
			background:map-get($colors, _01-01);
			border-radius:100%;
		}
	}
}

.input-checkbox {

	input + span {

		svg {
			width:16px; height:16px;
			fill:map-get($colors, _01-01);
		}
	}
}


/*
 * Form Light Modifier
 */

.form-light {

    .input-radio,
    .input-checkbox {

        input + span {
    		border-color:#FFF;
    	}
    }
    .input-radio input + span:after {
    	background:#FFF;
    }

    .input-checkbox input + span svg {
    	fill:#FFF;
    }
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* GOOGLE RECAPTCHA ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.g-recaptcha {
	display:none;
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* FORM ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.form-wrapper {
	width: 100%;
	max-width: 60rem;
}
	.form {
		margin-top:-24px;

		@media screen and (max-width:768px)
		{
			margin-top:-18px;
		}

		a {
			@include animate;
			text-decoration:underline;

			@media screen and (min-width:769px)
			{
				&:hover,
				&:focus {
					color:map-get($colors, _01-01);
					text-decoration:none;
				}
			}
		}
	}
		.form-btn_wrapper {
			@include flex-row;
			align-items: flex-end;
            justify-content: space-between;
			flex-wrap: wrap;
			padding-top:10px;

            @media screen and (max-width:768px)
            {
                padding-top:24px;
            }
		}
		.form-bottom {
			padding-top:48px;

            @media screen and (max-width:768px)
            {
                padding-top:24px;
            }
		}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* INDEX FORM ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.contact-form {
    clip-path: polygon(0 0, 100% 10rem, 100% 100%, 0 100%);
    margin-top: -8rem;
    padding: 14rem 10% 8rem;
    display: flex;
    justify-content: center;
    position: relative;
	.contact-form__background-image {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 0;
		picture {
			width: 100%;
			height: 100%;
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: -5px;
        background-color: rgba($color: map-get($colors, _02-01), $alpha: 0.9);
		z-index: 1;
    }
	.contact-form__logo {
		width: 8rem;
		margin: 0 0 2rem calc(50% - 4rem);
		z-index: 5;
		position: relative;
	}
	h2 {
		margin-bottom: 1rem;
	}
	p {
		color: map-get($colors, _white);
	}
	.form-wrapper {
		z-index: 2;
		.field {
			padding-top: 15px;
		}
		input, textarea, select {
			border: 1px solid map-get($colors, _01-02);
			background-color: map-get($colors, _02-01);
			color: map-get($colors, _white);
			padding: 0 10px;
			height: 50px;
		}
		textarea {
			height: 200px;
			padding: 10px;
		}
		select {
			background-color: map-get($colors, _02-01);
			color: map-get($colors, _tb);
			font-weight: bold;

			background-image:
				linear-gradient(45deg, transparent 50%, map-get($colors, _white) 50%),
				linear-gradient(135deg, map-get($colors, _white) 50%, transparent 50%),
				linear-gradient(to right, map-get($colors, _tb), map-get($colors, _tb));
			background-position:
				calc(100% - 24.5px),
				calc(100% - 19.5px),
				100% 0;
			background-size:
				5px 5px,
				5px 5px,
				49px 49px;
			background-repeat: no-repeat;
		}
		.input-checkbox {
			// flex-wrap: wrap;
			.checkbox-span {
				background-color: rgba($color: white, $alpha: 0.5);
				svg {
					fill: map-get($colors, _tb);
				}
			}
			label {
				color: #fff;
				a {
					color: #fff;
				}
				span {
					padding-top: 5px;
				}
			}
		}
		.field-label {
			color: #fff;
		}
		.form {
			margin-top: 0;
		}
		.form-btn_wrapper {
			.btn {
				margin-top: 1rem;
			}
		}

		.field-feedback {
			background-color: #131C2F;
			width: 100%;
			&.js-field-feedback {
				margin-top: 10px;
			}
			span {
				padding: 5px 10px;
			}
		}
		.input-checkbox {
			flex-wrap: wrap;
		}
	}
}
