.recent-events {
    margin-top: 8rem;
    margin-bottom: 8rem;
    margin-left: 10%;
    overflow-x: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    @media screen and (max-width:768px) {
        flex-wrap: wrap;
    }
    .col {
        width: calc(50% - 5rem);
        &:first-child {
            margin-bottom: 8rem;
        }
        @media screen and (max-width: 900px) {
            width: calc(50% - 2rem);
            &:first-child {
                margin-bottom: 4rem;
            }
        }
        @media screen and (max-width:768px) {
            width: 100%;
            &:first-child { 
                margin-right: 10%;
            }
        }
    }
    &::after {
        content: '';
        position: absolute;
        bottom: 2rem;
        left: 0;
        right: 10%;
        background-color: map-get($colors, _tb);;
        height: 0.2rem;
        z-index: 20;
        @media screen and (max-width:768px) {
            display: none;
        }
    }
}

.recent-events__quick-links {
    width: 100%;
    position: relative;
    .navigation__button {
        position: absolute;
        right: 2rem;
        top: calc(50% - 3rem);
        z-index: 20;
        @media screen and (max-width:768px) {
            top: auto;
            bottom: -2rem;
            right: 1rem;
        }
    }
    .swiper-pagination {
        bottom: -1.5rem;
        right: 10%;
        width: auto;
        top: auto;
    }
    .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
        background: map-get($colors, _tb);
    }
    @media screen and (max-width:768px) {
        &.swiper-container {
            overflow: visible;
        }
    }
}

.recent-events__content {
    right: 3.125rem;
    width: 100%;
    .title--main {
        margin-bottom: 2rem;
    }
    .body--main {
        margin-bottom: 1rem;
    }
}

.recent-events__quick-links__track {
    display: flex;
    position: relative;
    right: 0px;
}

.recent-events__quick-links_outer {
    width: 18%;
    margin-right: 1%;
    min-width: 300px;
    @media screen and (max-width:768px) {
        width: 90%;
    }
    .recent-events__quick-links__quick-link {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        text-align: center;
        width: 100%;
        height: 35rem;
        background-repeat: no-repeat;
        background-size: cover;
        color: #fff;
        position: relative;
        p {
            margin-bottom: 3rem;
            z-index: 1;
            position: relative;
        }
        .recent-events__background-image {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .recent-events__icon {
            content: '';
            position: absolute;
            top: 1rem;
            left: 1rem;
            height: 4rem;
            width: 4rem;
            border-radius: 50%;
        }
    }
}