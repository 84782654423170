.stand-out {
    background-color: #3867B7;
    color: white;
    display: flex;
    flex-wrap: wrap; 
    justify-content: center;
    align-content: flex-start;
    position: relative;
    .stand-out__background-image { 
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .stand-out__logo-image {
            content: '';
            position: absolute;
            bottom: 4rem;
            right: -10rem;
            object-fit: contain;
            object-position: center;
            width: 20rem;
            height: 20rem;
            opacity: 0.2;
        }
    }
    .stand-out__title {
        width: 50%;
        text-align: center;
        margin: 4% 0;
        z-index: 5;
        @media screen and (max-width: 1000px) {
            width: 60%;
        }
        @media screen and (max-width: 600px) {
            width: 80%;
        }
    }
    .stand-out__box {
        width: 100%;
        margin: 0 10% 4%;
        display: flex;
        justify-content: space-between;
        z-index: 5;
        @media screen and (max-width: 1000px) {
            flex-wrap: wrap;
        }
        .stand-out__double-image {
            width: 50%;
            margin-bottom: 3rem;
            @media screen and (max-width: 1100px) {
                width: 60%;
            }
            @media screen and (max-width: 1000px) {
                width: 100%;
                margin: 0 auto 2rem;
            }
            .stand-out__double-image-inner {
                position: relative;
                display: flex;
                align-items: flex-end;
                @media screen and (max-width: 1000px) {
                   justify-content: center;
                }
                &::before {
                    content: 'HIGH QUALITY';
                    position: absolute;
                    font-size: 3rem;
                    opacity: 0.6;
                    z-index: 2;
                    top: 0;
                    font-family:map-get($font_families, _02);
                    left: 25%;
                    @media screen and (max-width: 1000px) {
                        font-size: 2rem;
                        left: 50%;
                    }
                }
                .image-1 {
                    width: 50%;
                    @media screen and (max-width: 1000px) {
                        width: 60%;
                    }
                }
                .image-2 {
                    width: 40%;
                    margin: 0 0 -3rem -8rem;
                    @media screen and (max-width: 1000px) {
                        width: 50%;
                    }
                }
            }
        }
        .stand-out__content {
            width: 50%;
            position: relative;
            padding: 4rem 0;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            @media screen and (max-width: 1100px) {
                width: 40%;
            }
            @media screen and (max-width: 1000px) {
                width: 100%;
                padding: 4rem 0 0;
            }
            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 2px;
                z-index: 100;
                background-color: white;
                top: 0;
                bottom: 0;
                right: 0;
                @media screen and (max-width: 1000px) {
                    display: none;
                }
            }
            .stand-out_content-inner {
                max-width: 30rem;
                @media screen and (max-width: 1000px) {
                    max-width: 100%;
                }
                &.animate {
                    p {
                        transform: translateY(0) scale(1);
                        opacity: 1;
                    }
                }
                p {
                    margin-bottom: 1rem;
                    opacity: 0;
                    transform: translateY(-100px);
                    transition-duration: 0.3s;
                    transition-delay: 0.3s;
                    transition-property: transform opacity;
                }
                .p--bold {
                    font-size: 1.4rem;
                }
                .btn {
                    margin-top: 1rem;
                }
            }
        }
    }
}