/* ------------------------------------------------------------------------------------------------------------------------ */
/* PB BLOCK: GENERIC ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block_generic {

    .pb-block_content > .grid {

        @media screen and (max-width:768px) {
            margin-top:-24px;
        }

        & > .column {

            @media screen and (max-width:768px) {
                padding-top:24px;

                &.m_order-1 {
                    order:1;
                }
                &.m_order-2 {
                    order:2;
                }
                &.m_order-3 {
                    order:3;
                }
            }
        }
    }
}
