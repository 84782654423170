.breadcrumb {
    width: 100%;
    margin: 0 0 2rem;
    a {
        font-size: 14px;
        color: rgb(148, 148, 148); 
        &::after {
            content: ' -';
        }
        &:last-child {
            &::after {
                content: '';
            }
        }
    }
}