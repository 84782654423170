.blogs-list-body {
    width: calc(100% + 40px);
    margin-left: -20px;
    padding: 4rem 10% calc(4rem - 40px);
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    .blogs-list-body__row {
        width: 100%;
        margin-bottom: 40px;
        display: flex;
        padding: 0 20px;
        box-sizing: border-box;
        //Blog 
        .blogs-list-body__blog-image {
            width: 50%;
            height: 100%;
        }
        .blogs-list-body__blog-content {
            width: 50%;
            padding: 40px 0 40px 40px;
        }
        @media only screen and (max-width: 1000px) {
            flex-wrap: wrap;
            .blogs-list-body__blog-image {
                width: 100%;
                height: 250px;
            }
            .blogs-list-body__blog-content {
                width: 100%;
                padding: 20px 0 0;
            }
        }
        @media only screen and (max-width: 650px) {
            .blogs-list-body__blog-content {
                .blogTitle {
                    font-size: 1.5rem;
                }
            }
        }
    }
    .blogs-list-body__col {
        width: 33.33%;
        padding: 0 20px;
        margin-bottom: 40px;
        box-sizing: border-box;
        @media only screen and (max-width: 1200px) {
            width: 50%;
        }
        @media only screen and (max-width: 650px) {
            width: 100%;
        }
        .blogs-list-body__inner {
            width: 100%;
            //Blog 
            .blogs-list-body__blog-content {
                margin-top: 20px;
                .blogTitle {
                    font-size: 1.5rem;
                    font-weight: bold;
                    margin: 10px 0 20px;
                }
            }
        }
    }
    //Blog 
    .blogs-list-body__blog-image {
        width: 100%;
        height: 250px;
        border: 1px solid #E2E2E2;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    .blogs-list-body__blog-content {
        width: 100%;
        .dateP {
            font-size: 16px;
            color: #848484;
            font-weight: bold;
        }
        .blogTitle {
            font-size: 2.5rem;
            font-weight: bold;
            margin: 10px 0 20px;
        }
        .slugP {
            font-size: 16px;
            line-height: 24px;
            max-height: 96px;
            overflow: hidden;
        }
        .blogs-list-body__blog-footer {
            display: flex;
            margin-top: 20px;
            .blogs-list-body__blog-footer-socials {
                margin-right: 10px;
                border-right: 1px solid #707070;
                .socialBtn {
                    margin-right: 10px;
                }
            }
            .readMoreBtn {
                color: #272727;
                font-weight: bold;
            }
        }
    }
}