/* ------------------------------------------------------------------------------------------------------------------------ */
/* TYPOGRAPHY ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.title--tb{
    color: map-get($map: $colors, $key:_tb);
}

.title--be {
    color: map-get($colors, _be);
}

.p--tb{
    color: map-get($colors, _tb);
}

.typography {


    /*
     * Headings
     */

    h1, h2, h3, h4, h5, h6 {
        font-weight:500;
    }


    /*
     * Strong
     */

    strong {
        font-weight:700;
    }


    /*
     * Lists
     */

    ul:not(.archive-item_categories-list) {
        list-style:none;

        li {
            @include relative;
            padding-left:32px;

            @media screen and (max-width:768px) {
                padding-left:24px;
                text-align:left;
            }

            &:not(:last-child) {
                padding-bottom:1em;
            }

            &:before {
                @include absolute;
                content:"";
                top:0; left:0;
                margin-top:10px;
                width:10px; height:10px;
                background:map-get($colors, _01-01);
                border-radius:100%;

                @media screen and (max-width:768px) {
                    margin-top:8px;
                    width:9px; height:9px;
                }
            }
        }
    }

    ol {
        list-style:none;
        counter-reset:ol-counter;

        li {
            @include flex-row;
            counter-increment:ol-counter;

            @media screen and (max-width:768px) {
                text-align:left;
            }

            &:not(:last-child) {
                padding-bottom:1em;
            }

            &:before {
                content:counter(ol-counter);
                flex-shrink:0;
                padding-right:24px;
                color:map-get($colors, _01-01);
                font-weight:500;
            }
        }
    }


    /*
     * Anchors
     */

    a:not(.btn):not(.link) {
        @include animate;
        color:map-get($colors, _01-01);
        font-weight:500;
        text-decoration:underline;

        @media screen and (min-width:769px) {
            &:hover,
            &:focus {
                color:map-get($colors, _01-01);
                text-decoration:none;
            }
        }
    }


    /*
     * Spacing (Normal)
     */

    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, ul, ol, .btns-wrapper {

        &:not(:first-child) {
            padding-top:24px;

            @media screen and (max-width:768px) {
                padding-top:18px;
            }
        }
    }


    /*
     * Spacing (Shorter)
     */

    &.half-spacing {

        h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, ul, ol, .btns-wrapper {

            &:not(:first-child) {

                @media screen and (min-width:769px) {
                    padding-top:18px;
                }
            }
        }
    }


    /*
     * Light Tipography
     */

    &.typo-light {
        color:#FFF;

        h1, h2, h3, h4, h5, h6, strong {
            color:#FFF;
        }

        a:not(.btn):not(.link) {
            color:#FFF;

            @media screen and (min-width:769px) {
                &:hover,
                &:focus {
                    color:map-get($colors, _01-01);
                }
            }
        }

        a.btn {
            color:#FFF;
        }
    }
}

// hero

.hero {
    h1, h2, h3, h4, h5, h6 {
        color: white;
        font-weight:500;
    }
}

.page-text {
    padding: 2rem 2rem;
    max-width: 52rem;
    margin: 0 auto;
    @media screen and (min-width:769px) {
        padding: 4rem 2rem;
    }
}
