/* ------------------------------------------------------------------------------------------------------------------------ */
/* PRELOAD ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.preload * {
    transition: none !important;
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* WRAPPER ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.wrapper {
    @include animate;
    @include flex-column;
    flex-shrink:0;
    margin-top:map-get($header_height, _desktop);
    min-height:calc(100vh - #{map-get($header_height, _desktop)});
    overflow:hidden;

    @media screen and (max-width:768px) {
        margin-top:map-get($header_height, _mobile);
        min-height:calc(100vh - #{map-get($header_height, _mobile)});
    }
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* MAIN CONTAINER --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.main-container {
    @include flex-column;
    flex:1 1 auto;
}
