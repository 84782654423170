.long-links {
    margin: 2rem 10% 8rem;
    .long-links__long-link {
        width: 100%;
        padding: 2rem 0;
        // 
        &:nth-child(odd) {
            &::before {
                margin-left: 25%;
            }
            .long-links__long-link__img {
                right: 25%;
                @media screen and (max-width: 1100px) { 
                    margin-right: 5%;
                    right: 0;
                }
            }
            .long-links__long-link__content {
                margin-left: 50%;
                @media screen and (max-width: 1100px) {
                    margin-left: 5%;
                }
            }
        }
        &:nth-child(even) {
            &::before {
                margin-right: 25%;
            }
            .long-links__long-link__img {
                left: 25%;
                @media screen and (max-width: 1100px) { 
                    margin-left: 5%;
                    left: 0;
                }
            }
            .long-links__long-link__content {
                margin-right: 50%;
                @media screen and (max-width: 1100px) {
                    margin-right: 5%;
                }
            }
        }
        &::before {
            content: '';
            display: block;
            width: 75%;
            height: 0.2rem;
            background-color: map-get($colors, _main);
            margin: 6rem 0 10rem;
        }
        
        &.long-links__long-link--em {
            &::before {
                background-color: map-get($colors, _em);
            }
        }
        &.long-links__long-link--c {
            &::before {
                background-color: map-get($colors, _c);
            }
        }
        &.long-links__long-link--ch {
            &::before {
                background-color: map-get($colors, _ch);
            }
        }
        &.long-links__long-link--tb {
            &::before {
                background-color: map-get($colors, _tb);
            }
        }

        // img con
        .long-links__long-link__img {
            top: 16.2rem;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            @media screen and (max-width: 1100px) {
                width: 95%;
                height: 20rem;
                position: relative;
                top: 0;
                margin-bottom: -2rem;
            }
        }   
        // content
        .long-links__long-link__content {
            z-index: 2;
            position: relative;
            width: 50%;
            background-color: map-get($colors, _01-02);
            padding: 3rem;
            box-sizing: border-box;
            @media screen and (max-width: 1100px) {
                width: 95%;
            }
            .title--main {
                margin-bottom: 2rem;
            }   
            p {
                margin-bottom: 1rem;
            }
            .long-links__long-link__button {
                margin-top: 2rem;
            }
        }
    }
}