.hero {
    max-width: 100%;
    height: 100vh;
    position: relative;
    &.auto-height {
        height: auto;
        .hero-banner-outer {
            height: auto;
        }
    }
    .hero-banner-outer {
        width: 100%;
        height: 100vh;
        z-index: 5;
        position: relative;
        .hero-banner-slider {
            height: 100%;
            width: 100%;
            .hero-banner-slider__swiper-container {
                width: 100%;
                height: 100%;
                .hero-banner-slider__slide {
                    width: 100%;
                    picture {
                        height: 100%;
                        width: 100%;
                    }
                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }
        }
    }
    .hero-banner-outer__video {
        object-fit: fill;
        aspect-ratio: 16 9 ;
        width: 100%;
        z-index: 1;
        @media only screen and (max-width: 767px) {
            aspect-ratio: 1.2;
        }
    }
    .hero-banner-outer__content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
        pointer-events: none;
        .hero-banner-outer__content-inner {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            position: relative;
            z-index: 0;
        }
    }
}


.hero__content {
    margin-top: 10rem;
    justify-self: center;
    align-self: center;
    text-align: center;
    max-width: 800px;
    pointer-events: all;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 5;
} 

.hero__content__logo {
    margin-bottom: 7%;
    width: 40%;
    
    & img {
        width: 100%;
    }
}

.hero__button .btn {
    position: fixed;
    pointer-events: all;
    bottom: 10%;
    right: -70px;
    text-transform: uppercase;
    padding: 10px 2.5rem;
    transform: rotate(-90deg);
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    z-index: 4;
}

.hero__down-btn-con {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: all;
    .hero__down-btn {
        display: block;
        height: 40px;
        width: 40px;
        cursor: pointer;
        z-index: 2;
        position: relative;
        animation: heroDownAni 3s infinite linear;
        transform: translateY(0);
        @keyframes heroDownAni {
            0%   {transform: translateY(0)}
            50% {transform: translateY(-10px)}
            100% {transform: translateY(0)}
        }
        .hero__down-icon {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
        &:hover {
            opacity: 0.8;
        }
    }
    &::before {
        content: '';
        position: absolute;
        top: -5rem;
        left: -8rem;
        right: -8rem;
        bottom: -11rem;
        border-radius: 50%;
        background-color: transparent;
        border: 1px solid white;
        z-index: 0;
        animation: rotate 5s infinite linear;
        transform: rotate(0);
    }
    &::after {
        content: '';
        position: absolute;
        top: -3rem;
        left: -6rem;
        right: -6rem;
        bottom: -9rem;
        border-radius: 50%;
        background-color: transparent;
        border: 1px solid white;
        z-index: 0;
        animation: rotate 5s infinite linear;
        transform: rotate(0);
    }
 
    @keyframes rotate {
        0%   {transform: rotate(0)}
        100% {transform: rotate(360deg)}
    }
}