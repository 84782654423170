.video-row {

    .video-row__box {
        background-color: map-get($colors, _01-02);
        margin-bottom: 4rem;
        padding: 4rem 10%;
        display: flex;
        align-items: center;
        @media screen and (max-width: 900px) {
            flex-wrap: wrap-reverse;
        }
        &:nth-child(even) {
            flex-direction: row-reverse;
            .video-row__content {
                padding: 0 0 0 4rem;
            }
        }
        .video-row__content {
            width: 40%;
            padding: 0 4rem 0 0;
            @media screen and (max-width: 900px) {
                width: 100%;
                padding: 4rem 0 0 !important;
            }
            h2 {
                font-size: 2rem;
                margin-bottom: 1rem;
            }
            .btn {
                margin-top: 2rem;
            }
        }
        .video-row__video {
            width: 60%;
            height: 30rem;
            background-color: map-get($colors, _01-03);
            display: flex;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: 900px) {
                width: 100%;
                height: 20rem;
            }
        }
    }
}