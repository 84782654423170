.info-row {
    margin: 8rem 10% 0;
    .info-row__box {
        display: flex;
        width: 100%;
        margin: 0 0 8rem;
        padding: 0 0 8rem;
        position: relative;
        @media screen and (max-width: 1100px) {
            flex-wrap: wrap-reverse;
        }
        &.right {
            flex-direction: row-reverse;
            .info-row__content {
                margin-left: -4rem;
                margin-right: 0;

                @media screen and (max-width: 1100px) {
                    margin-right: 3%;
                    margin-left: 7%;
                }
            }
            &::after {
                right: 0;
                left: auto;
            }
        }
        &.single {
            padding: 0;
            &::after {
                content: none;
            }
        }
        &:nth-child(even) {
            flex-direction: row-reverse;
            .info-row__content {
                margin-left: -4rem;
                margin-right: 0;

                @media screen and (max-width: 1100px) {
                    margin-right: 3%;
                    margin-left: 7%;
                }
            }
            &::after {
                right: 0;
                left: auto;
            }
        }
        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            height: 0.2rem;
            width: 80%;
            background-color: map-get($colors, _tb);
        }
        .info-row__content {
            width: 50%;
            background-color: map-get($colors, _01-02);
            padding: 2rem;
            box-sizing: border-box;
            margin-top: 4rem;
            margin-right: -4rem;
            z-index: 1;
            h2 {
                margin-bottom: 1rem;
                font-size: 2rem;
            }
            p {
                margin-bottom: 1rem;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            @media screen and (max-width: 1100px) {
                width: 90%;
                margin-top: -4rem;
                margin-right: 7%;
                margin-left: 3%;
            }
        }
        .info-row__img {
            width: calc(50% + 4rem);
            background-color: map-get($colors, _01-03);
            margin-bottom: 4rem;
            @media screen and (max-width: 1100px) {
                width: 100%;
                height: 20rem;
                margin-bottom: 0;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}