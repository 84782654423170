.blog-intro-bar {
    background-color: #223050;
    padding: 130px 10% 4rem;
    .breadcrumb {
        margin-bottom: 0.5rem;
    }
    h1 {
        color: #fff;
        font-size: 3rem;
        max-width: 1000px;
    }
    .blog-intro-bar__author {
        display: flex;
        align-items: center;
        margin-top: 20px;
        .intro-bar__icon {
            height: 40px;
            width: 40px;
            min-width: 40px;
            background-color: #fff;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .blog-intro-bar__title-area {
            display: flex;
            flex-direction: column;
            font-weight: bold;
            margin-left: 10px;
            .author {
                color: #fff;
                font-size: 16px;
                line-height: 16px;
                margin-bottom: 4px;
            }
            .date {
                color: #848484;
                font-size: 16px;
                line-height: 16px;
            }
        }
    }
}

.blog-body {
    width: 100%;
    padding: 4rem 10%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    .blog-body__content-col {
        width: calc(100% - 420px);
        @media only screen and (max-width: 1350px) {
            width: 100%;
        }
        .blog-body__blog-image {
            width: 100%;
            border: 1px solid grey;
            position: relative;
            &::after {
                content: '';
                display: block;
                padding-bottom: 56.25%;
            }
            .blog-body__blog-image-inner {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
        .blog-body__content {
            margin-top: 20px;
        }
    }
    .blog-body__sidebar {
        width: 400px;
        margin-left: 20px;
        padding-left: 20px;
        box-sizing: border-box;
        border-left: 1px solid #D5D5D5;
        @media only screen and (max-width: 1350px) {
            width: 100%;
            border-left: none;
            border-top: 1px solid #D5D5D5;
            padding-left: 0;
            margin-left: 0;
            margin-top: 40px;
            padding-top: 40px;
        }
        .title {
            font-weight: bold;
            margin-bottom: 10px;
        }
        // blog
        .blog-body__blog-row {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #D5D5D5;
            padding-bottom: 10px;
            margin-bottom: 10px;
            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
                margin-bottom: 0; 
            }
            .blog-body__img-con {
                width: 70px;
                min-width: 70px;
                height: 50px;
                border: 1px solid grey;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
            .blog-body__blog-row-content {
                padding-left: 10px;
                p {
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 20px;
                }
            }
        }
    }
}