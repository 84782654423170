/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADER ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.header {
    padding:48px;
    color:#999;
    font-size:14px;
    text-align:center;
    text-transform:uppercase;
    border-bottom:1px solid #E0E0E0;
}
