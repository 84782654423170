.quick-links {
    width: 100%;
    margin: 4% auto;
    padding: 0 1rem;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
    @media screen and (max-width: 1500px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 1000px) {
        grid-template-columns: repeat(2, 1fr);
    }
    //card
    .quick-links__quick-link {
        background-color:map-get($colors, _main);
        color: white;
        width: 100%;
        height: 40rem;
        background-size: cover;
        position: relative;
        &.darken {
            a {
                .quick-links__quick-link__content{

                    .quick-links__darken-overlay {
                        background-color: rgba($color: #000000, $alpha: 0.4);
                    }
                }
            }
        }
        a {
            .quick-links__quick-link__content{
                .quick-links__darken-overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: rgba($color: #000000, $alpha: 0);
                    z-index: 20;
                    transition: 0.3s;
                }
            }
        }
        &.quick-links__quick-link--em {
            a .quick-links__quick-link__content {
                &::before {
                    border-color: map-get($colors, _em);
                }
            }
        }
        &.quick-links__quick-link--c {
            a .quick-links__quick-link__content {
                &::before {
                    border-color: map-get($colors, _c);
                }
            }
        }
        &.quick-links__quick-link--ch {
            a .quick-links__quick-link__content {
                &::before {
                    border-color: map-get($colors, _ch);
                }
            }
        }
        &.quick-links__quick-link--be {
            a .quick-links__quick-link__content {
                &::before {
                    border-color: map-get($colors, _be);
                }
            }
        }
        &.quick-links__quick-link--tb {
            a .quick-links__quick-link__content {
                &::before {
                    border-color: map-get($colors, _tb);
                }
            }
        }
        &.quick-links__quick-link--enquire {
            background-color: map-get($colors, _tb);
            a .quick-links__quick-link__content {
                background-color: transparent;
                &::before {
                    border-color: white;
                }
            }
        }

        a .quick-links__quick-link__content {
            height: 100%;
            width: 100%;
            padding: 1rem;
            box-sizing: border-box;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: white;
            justify-content: center;
            text-align: center;
            &::before {
                content: '';
                position: absolute;
                top: 1rem;
                left: 1rem;
                right: 1rem;
                bottom: 1rem;
                border: 1px solid white;
                z-index: 1;
            }
            h3 {
                z-index: 10;
                @media screen and (max-width:768px) {
                    font-size: 16px;
                }
            }
            .quick-link__background-image {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 0;
                &::after {
                    content: '';
                    background-color: rgba($color: #000000, $alpha: 0.5);
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .quick-links__icon {
            background-repeat: no-repeat !important;
            background-size: contain !important;
            height: 3rem;
            width: 3rem;
            min-height: 3rem;
            margin-bottom: 0.5rem;
            z-index: 10;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
        &.quick-links__quick-link--enquire {
            display: none;
        }
        @media screen and (max-width: 1500px) {
            &.quick-links__quick-link--enquire {
                display: block;
            }
        }
        @media screen and (max-width: 1000px) {
            height: auto;
            &::after {
                content: "";
                display: block;
                padding-bottom: 100%;
            }
        }
    }
}