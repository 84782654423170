.hero-slider {
    max-width: 100%;
    height: 80vh;
    position: relative;
    .hero-slider__swiper-container {
        height: 100%;
        width: 100%;
        .hero-slider__slide {
            width: 100%;
            picture {
                height: 100%;
                width: 100%;
            }
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
        .hero-slider__slider-nav-buttons {
            position: absolute;
            top: 0;
            left: 5%;
            right: 5%;
            bottom: 0;
            display: flex;
            justify-content: space-between;
            z-index: 1;
            .slider_btn-next {
                height: 5rem;
                width: 5rem;
            }
            .swiper-button-prev {
                transform: rotate(180deg);
            }
        }

        .swiper-pagination-bullet {
            height: 1rem;
            width: 1rem;
            background-color: white;
            opacity: 1;
            position: relative;
            margin: 0 0.5rem;
            &::before {
                content: '';
                position: absolute;
                top: -0.2rem;
                left: -0.2rem;
                right: -0.2rem;
                bottom: -0.2rem;
                border-radius: 50%;
                border: 1px solid white;
            }
            &.swiper-pagination-bullet-active {
                background-color: map-get($colors, _tb);
            }
        }
    }
}