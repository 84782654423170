/* ------------------------------------------------------------------------------------------------------------------------ */
/* THEME COLORS ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

$colors: (
    _white: #FFF,
    _01-01: black,
    _01-02: #f5f0f0, // light grey - bg
    _01-03: #212122, // dark grey
    _02-01: #1b2c55,
    _main: #000249,
    _tb: #ec671c,
    _ch: #4d8ae3,
    _em: #0b4f89,
    _c: #245664,
    _be: #d8b40b,
    _error: #D91E18,
    _alert: #F89406,
    _valid: #00B16A,
    _update: #1E8BC3
); 


/* ------------------------------------------------------------------------------------------------------------------------ */
/* WIDTHS ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

$widths: (
    _large: 1440px,
    _base: 1220px,
    _medium: 980px,
    _medium_small: 780px,
    _small: 680px,
    _xsmall: 480px
);

$header_height: (
    _desktop: 0px,
    _mobile: 0px
);



/* ------------------------------------------------------------------------------------------------------------------------ */
/* LINE HEIGHTS ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

$line_heights: (
    _base: 1.6,
    _small: 1.5
);


/* ------------------------------------------------------------------------------------------------------------------------ */
/* FONT SIZES ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

$font_sizes: (
    _base: 18px,
    _small: 16px
);


/* ------------------------------------------------------------------------------------------------------------------------ */
/* FONT FAMILIES ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

@import url("https://use.typekit.net/jsd7ybg.css");
@font-face{
    font-family:"Metropolis-Light";
    src:url("../fonts/Metropolis-Light.woff2") format("woff2"),url("fonts/Metropolis-Light.woff2") format("woff");
}
@font-face{
    font-family:"Metropolis-Regular";
    src:url("../fonts/Metropolis-Light.woff2") format("woff2"),url("fonts/Metropolis-Light.woff2") format("woff");
}


$font_families: (
    _01: (Metropolis-Regular),
    _02: (freight-text-pro),
    _03: (Metropolis-Light),
);


/* ------------------------------------------------------------------------------------------------------------------------ */
/* FLUID TEXT ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

.hide {
    display: none !important;
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
    $u1:unit($min-vw);
    $u2:unit($max-vw);
    $u3:unit($min-font-size);
    $u4:unit($max-font-size);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {
            font-size:$min-font-size;
            @media screen and (min-width:$min-vw) {
                font-size:calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }
            @media screen and (min-width:$max-vw) {
                font-size:$max-font-size;
            }
        }
    }
}

.stop-scrolling {
    height: 100%;
    overflow: hidden;
  }

html {
    scroll-behavior: smooth;
}