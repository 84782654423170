.stand-out-circles {
    width: 100%;
    padding: 4rem 10%;
    box-sizing: border-box;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    @media screen and (max-width: 1000px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 700px) {
        grid-template-columns: repeat(2, 1fr);
    }
    .stand-out-circle__background-image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        picture {
            width: 100%;
            height: 100%;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }
    &.stand-out-circles--em {
        &::before {
            background-color: rgba($color: map-get($colors, _em), $alpha: 0.8);
        }
    }
    &.stand-out-circles--c {
        &::before {
            background-color: rgba($color: map-get($colors, _c), $alpha: 0.8);
        }
    }
    &.stand-out-circles--ch {
        &::before {
            background-color: rgba($color: map-get($colors, _ch), $alpha: 0.8);
        }
    }
    &.stand-out-circles--tb {
        &::before {
            background-color: rgba($color: map-get($colors, _tb), $alpha: 0.8);
        }
    }
    &.stand-out-circles--be {
        &::before {
            background-color: rgba($color: map-get($colors, _be), $alpha: 0.8);
        }
    }
    .stand-out-circles__col {
        z-index: 2;
        position: relative;
        .stand-out-circles__icon {
            width: 60%;
            background-color: white;
            border-radius: 50%;
            position: relative;
            margin: 0 auto 2rem;
            @media screen and (max-width: 1000px) {
                width: 80%;
            }
            @media screen and (max-width: 500px) {
                width: 100%;
            }
            &::after {
                content: '';
                display: block;
                padding-bottom: 100%;
            }
            .stand-out-circles__inner {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 60%;
                }
            }
        }
        .stand-out-circles__title {
            color: white;
            margin: 0 auto;
            text-align: center;
            font-size: 1.2rem;
            width: 80%;
            @media screen and (max-width: 1000px) {
                font-size: 1.1rem;
                width: 90%;
            }
        }
    }
}